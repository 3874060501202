<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">{{ $t('help.title') }}</div>
                <el-button type="primary" icon="el-icon-question" plain disabled>{{ $t('help.feedback') }}</el-button>
            </div>
        </el-card>
        <el-row :gutter="20" class="mb-50">
            <el-col :span="6">
                <div class="bg-white box-shadow border-r">
                    <div
                        v-for="(item, index) in navMenu" :key="index"
                        class="p-20 is-link menu-item d-flex-center"
                        :class="{ active: $route.params.id == item.id }"
                        @click="onNav(item.id)"
                    >
                        <i class="el-icon-document mr-10" />
                        <span class="mr-auto" v-text="item.name" />
                        <i class="el-icon-arrow-right" />
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <el-card class="rich-view">
                    <div v-loading="loading" class="" v-html="info.content" />
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // active: 0
            navMenu: [],
            loading: true,
            info: {
                title: '',
                content: '<br/><br/><br/><br/>'
            }
        }
    },
    watch: {
        $route: function (to, from){
            if (to.params.id == from.params.id) return
            this.fatchData()
        }
    },
    created: function () {
        this.fatchList()
        this.fatchData()
    },
    methods: {
        fatchList: function (){
            this.$request.post('help', this.query).then(({ data }) => {
                for(const item of data){
                    this.navMenu.push({
                        name: item.title,
                        id: item.id
                    })
                }
            })
        },
        fatchData: function (){
            this.loading = true
            this.$request.get('help/item/id/' + this.$route.params.id).then(({ data }) => {
                this.info = data
                this.loading = false
            })
        },
        onNav: function (route){
            if (!route) return
            if (this.$route.params.id == route) return
            this.$nav.replace('/help/'+route)
        }
    }
}
</script>
<style lang="scss">
.menu-item{
    border-left: 4px solid transparent;
}
.menu-item.active,
.menu-item:hover{
    border-left: 4px solid $--color-primary;
    color: $--color-primary;
}
.rich-view{
    p{
        line-height: 24px;
        text-indent: 2em;
    }
    img{
        max-width: 100%;
        display: block !important;
    }
}
</style>